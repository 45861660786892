
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonRow, IonCol, IonButton, IonSegment, IonSegmentButton, IonLabel, IonChip, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonSpinner } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import moment from "moment";

export default defineComponent({
  name: 'InseratCreateStep6',
  data () {
    return {
      finishedLoading: false,
      finishedWithoutErrors: false,
      inseratHasImage: false,
      submitted: false,
      currentSegment: 0,
      inserat: null,
      finishedLoadingInserat: false,

    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonButton,
    IonSegment,
    IonSegmentButton,
    IonLabel, IonChip, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonSpinner
  },
  created() {



    // check if user is logged in
    if (!localStorage.userId || !localStorage.userToken){
      // push to main
      this.$router.push({ path: '/login' })
    }

    // check if inserat and image was persisted
    if (!localStorage.persistedInserat) {
        // push to user inserats
        this.$router.push({ path: '/profile/inserats' })
    }
    if (localStorage.persistedImage){
        this.inseratHasImage = true;
    }


    // get inserat data
    axios.get(process.env.VUE_APP_API_URL + '/inserats/' + localStorage.persistedInserat)
        .then(response => {
          this.inserat = response.data;
          this.finishedLoadingInserat = true;
        })
        .catch(error => {
          console.log(error);
        });
  },
  methods: {
    convertCreatedAt(date: string){
      return moment(date).format("DD.MM.Y");
    },
    convertCreatedAtTime(date: string){
      return moment(date).format("HH:MM");
    },
    goToUserInserats() {
        this.$router.push({ path: '/profile/inserats'})
    },
    segmentChanged(ev: CustomEvent) {
      this.currentSegment = ev.detail.value;
    },
    cancel() {

      // reset inserat local storage
      localStorage.inseratStep = 0;
      localStorage.inseratType = null;
      localStorage.inseratTitle = null;
      localStorage.inseratDescription = null;
      localStorage.inseratPrice = null;
      localStorage.inseratCurrency = null;
      localStorage.inseratIsGoodCause = null;
      localStorage.inseratDonationRecipient = null;
      localStorage.inseratDonationRecipientId = null;
      localStorage.inseratCategoryId = null;
      localStorage.persistedInserat = null;
      localStorage.persistedImage = null;

      this.$router.push({ path: '/all' });
    },
    submit() {

      const formData = new FormData();
      formData.append("inserat", localStorage.persistedInserat);

      // submit inserat for checking, url: /submit-inserat
      axios.post(process.env.VUE_APP_API_URL + '/submit-inserat', formData, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
          .then(() => {
            this.submitted = true;
          })
          .catch(error => {
            console.log(error);
          });


      // reset inserat local storage
      localStorage.inseratStep = 0;
      localStorage.inseratType = null;
      localStorage.inseratTitle = null;
      localStorage.inseratDescription = null;
      localStorage.inseratPrice = null;
      localStorage.inseratCurrency = null;
      localStorage.inseratIsGoodCause = null;
      localStorage.inseratDonationRecipient = null;
      localStorage.inseratDonationRecipientId = null;
      localStorage.inseratCategoryId = null;
      localStorage.persistedInserat = null;
      localStorage.persistedImage = null;

    },
  }
});

