<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Fertig</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-header :translucent="true" v-if="inseratHasImage && !submitted">
      <ion-toolbar>
        <ion-segment @ionChange="segmentChanged($event)" value="0">
          <ion-segment-button value="0">
            <ion-label>Details</ion-label>
          </ion-segment-button>
          <ion-segment-button value="1">
            <ion-label>Preview</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" v-if="!submitted && currentSegment == 0">
      <div class="container">
        <ion-row class="sideMargins topMargins">
          <ion-col size="12">
            <strong class="capitalize">Inserat erfolgreich erstellt.</strong>
            <p v-if="!inseratHasImage">Du musst noch ein Bild hochladen, bevor du dein Inserat zur Prüfung schicken kannst.</p>
            <p v-if="inseratHasImage">Du kannst nun dein Inserat zur Prüfung schicken. Du bekommst eine Mail mit einem Status.</p>
          </ion-col>
          <ion-col size="12" style="margin-top: 20px;" v-if="inseratHasImage">
            <ion-button @click="submit()" color="secondary">Abschicken</ion-button>
          </ion-col>
          <ion-col size="12">
            <ion-button @click="goToUserInserats()" color="primary">Zu meinen Inseraten</ion-button>
          </ion-col>
        </ion-row>
      </div>
    </ion-content>

    <ion-content :fullscreen="true" v-if="!submitted && currentSegment == 1 && finishedLoadingInserat" style="margin-top: 20px;">

      <div style="z-index: 1;">
        <img :src="inserat.inseratImages[0].accessUrl" style="z-index: 1;">
      </div>

      <div class="onlyTopCornersRounded" style="background-color: white; margin-top: -70px; z-index: 20;transform: rotate(0deg); min-height: 60%;">
        <ion-row class="sidePaddings topPaddings">
          <ion-col size="12">
            <ion-chip v-if="inserat.isDonation" style="width: 100%; text-align: center; justify-content: center;" color="light">
              <ion-label>Erlös wird gespendet</ion-label>
            </ion-chip>
          </ion-col>

          <ion-col size="12">
            <br>
            <ion-label class="title" color="light">{{ inserat.title }}</ion-label>
            <br>
            <ion-label class="subtitle" color="light">Wird verkauft von @{{ inserat.createdBy.username }}</ion-label>
          </ion-col>
          <ion-col size="12">
            <br>
            <ion-label style="font-weight: bold; font-size: 18px; color: grey;" color="light">Beschreibung</ion-label>
            <br><br>
            <ion-label class="ion-text-wrap" color="light">{{ inserat.description }}</ion-label>
            <br>
            <ion-label class="ion-text-wrap" color="light" v-if="inserat.inseratType === 'auction'">Auktion beginnt in: </ion-label>
          </ion-col>
          <ion-col size="6">
            <br>
            <ion-label class="price" color="light"><span style="font-weight: bold; font-size: 46px;">{{ inserat.price }}{{ inserat.currency }}</span></ion-label>
            <br><br>
          </ion-col>
          <ion-col size="6">
            <br>
            <ion-button shape="round" v-if="inserat.inseratType === 'directbuy' ">Jetzt kaufen</ion-button>
            <ion-button shape="round" v-if="inserat.inseratType === 'auction'">Jetzt bieten</ion-button>
            <br><br>
          </ion-col>
          <ion-col size="12">
            <ion-card>
              <ion-card-header>
                <ion-row>
                  <ion-col size="3">
                    <img class="rounded-image imageCircle" src="http://via.placeholder.com/50x50.png?text=Avatar" alt="test" />
                  </ion-col>
                  <ion-col size="1"></ion-col>
                  <ion-col size="8">
                    <ion-card-subtitle>{{ inserat.createdBy.firstname }} {{ inserat.createdBy.lastname }}</ion-card-subtitle>
                    <ion-card-title>{{ inserat.createdBy.username }}</ion-card-title>
                  </ion-col>
                </ion-row>
              </ion-card-header>
            </ion-card>
          </ion-col>
        </ion-row>
      </div>
    </ion-content>

    <ion-content :fullscreen="true" v-if="!submitted && currentSegment == 1 && !finishedLoadingInserat" class="spinnerCenter">
      <ion-spinner name="dots"></ion-spinner>
    </ion-content>

    <ion-content :fullscreen="true" v-if="submitted">
      <div class="container">
        <ion-row class="sideMargins topMargins">
          <ion-col size="12" style="height: 250px; width: 100%; position: relative; margin-bottom: 50px;">
            <img class="rounded-image imageCircleAccepted" src="http://via.placeholder.com/250x250.png?text=Done" alt="test" style="max-width: 100%;max-height: 100%;overflow: auto;margin: auto;position: absolute;top: 0; left: 0; bottom: 0; right: 0;" />
          </ion-col>
          <ion-col size="12" style="margin-top: 20px;">
            <ion-button @click="goToUserInserats()" color="primary">Zu meinen Inseraten</ion-button>
          </ion-col>
        </ion-row>
      </div>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonRow, IonCol, IonButton, IonSegment, IonSegmentButton, IonLabel, IonChip, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonSpinner } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import moment from "moment";

export default defineComponent({
  name: 'InseratCreateStep6',
  data () {
    return {
      finishedLoading: false,
      finishedWithoutErrors: false,
      inseratHasImage: false,
      submitted: false,
      currentSegment: 0,
      inserat: null,
      finishedLoadingInserat: false,

    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonButton,
    IonSegment,
    IonSegmentButton,
    IonLabel, IonChip, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonSpinner
  },
  created() {



    // check if user is logged in
    if (!localStorage.userId || !localStorage.userToken){
      // push to main
      this.$router.push({ path: '/login' })
    }

    // check if inserat and image was persisted
    if (!localStorage.persistedInserat) {
        // push to user inserats
        this.$router.push({ path: '/profile/inserats' })
    }
    if (localStorage.persistedImage){
        this.inseratHasImage = true;
    }


    // get inserat data
    axios.get(process.env.VUE_APP_API_URL + '/inserats/' + localStorage.persistedInserat)
        .then(response => {
          this.inserat = response.data;
          this.finishedLoadingInserat = true;
        })
        .catch(error => {
          console.log(error);
        });
  },
  methods: {
    convertCreatedAt(date: string){
      return moment(date).format("DD.MM.Y");
    },
    convertCreatedAtTime(date: string){
      return moment(date).format("HH:MM");
    },
    goToUserInserats() {
        this.$router.push({ path: '/profile/inserats'})
    },
    segmentChanged(ev: CustomEvent) {
      this.currentSegment = ev.detail.value;
    },
    cancel() {

      // reset inserat local storage
      localStorage.inseratStep = 0;
      localStorage.inseratType = null;
      localStorage.inseratTitle = null;
      localStorage.inseratDescription = null;
      localStorage.inseratPrice = null;
      localStorage.inseratCurrency = null;
      localStorage.inseratIsGoodCause = null;
      localStorage.inseratDonationRecipient = null;
      localStorage.inseratDonationRecipientId = null;
      localStorage.inseratCategoryId = null;
      localStorage.persistedInserat = null;
      localStorage.persistedImage = null;

      this.$router.push({ path: '/all' });
    },
    submit() {

      const formData = new FormData();
      formData.append("inserat", localStorage.persistedInserat);

      // submit inserat for checking, url: /submit-inserat
      axios.post(process.env.VUE_APP_API_URL + '/submit-inserat', formData, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
          .then(() => {
            this.submitted = true;
          })
          .catch(error => {
            console.log(error);
          });


      // reset inserat local storage
      localStorage.inseratStep = 0;
      localStorage.inseratType = null;
      localStorage.inseratTitle = null;
      localStorage.inseratDescription = null;
      localStorage.inseratPrice = null;
      localStorage.inseratCurrency = null;
      localStorage.inseratIsGoodCause = null;
      localStorage.inseratDonationRecipient = null;
      localStorage.inseratDonationRecipientId = null;
      localStorage.inseratCategoryId = null;
      localStorage.persistedInserat = null;
      localStorage.persistedImage = null;

    },
  }
});

</script>

<style scoped>

.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

.sideMargins {
  margin-left: 25px;
  margin-right: 25px;
}

.topMargins {
  margin-top: 10%;
}

.sidePaddings {
  padding-left: 25px;
  padding-right: 25px;
}

.topPaddings {
  padding-top: 25px;
}

.imageCircle {
  border: 2px solid #1dc0c6;
}

.imageCircleDenied {
  border: 2px solid #c61d53;
}

.imageCircleAccepted {
  border: 2px solid #1dc620;
}

.rounded-image {
  border-radius: 50%;
}

.onlyTopCornersRounded {
  border-radius: 25px 25px 0 0;
}

.onlyBottomCornersRounded {
  border-radius: 0 0 25px 25px;
}

.noScroll {
  overflow: hidden;
}

</style>
